import React, { useEffect, useState } from "react";
import { isLoggedIn } from "../../components/Auth";
import Profile from "../profile";
import LoginForm from "./LoginForm";

const Login = () => {
  const [loginToken, setLoginToken] = useState(null);
  useEffect(() => {
    setLoginToken(isLoggedIn());
  }, []);

  return (
    <div>
      {loginToken === null ? (
        ""
      ) : loginToken !== undefined ? (
        <Profile />
      ) : (
        <LoginForm />
      )}
    </div>
  );
};

export const Head = () => <title>Account</title>;

export default Login;
